<template>
  <div>
    <div style="display: flex">
      <el-button type="primary" @click="add">新增</el-button>
    </div>
    <el-tabs
      v-model="activeName"
      type="card"
      @tab-click="handleClick"
      style="width: 100%; margin-top: 20px"
    >
      <el-tab-pane label="视频列表" name="video">
        <el-table :data="videoList" border :max-height="maxHeight">
          <el-table-column prop="id" label="资源ID"></el-table-column>
          <el-table-column prop="filename" label="资源名字"></el-table-column>
          <el-table-column prop="duration" label="资源时长">
            <template slot-scope="scope">
              {{ formatDuration(scope.row.duration) }}
            </template>
          </el-table-column>
          <el-table-column prop="duration" label="资源时长">
            <template slot-scope="scope">
              <el-button size="small" type="primary" @click="edit(scope.row.id, scope.row.filename)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="videoTotalRows"
          :current-page="videoPage"
          :page-size="20"
          style="margin-top: 20px"
          @current-change="handleChangePage"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="文件列表" name="file">
        <el-table :data="fileList" border :max-height="maxHeight">
          <el-table-column prop="id" label="资源ID"></el-table-column>
          <el-table-column prop="filename" label="资源名字"></el-table-column>
          <el-table-column prop="path" label="资源路径"></el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="fileTotalRows"
          :current-page="filePage"
          :page-size="20"
          style="margin-top: 20px"
          @current-change="handleChangePage"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import CourseApi from "../../request/CourseApi";
export default {
  data() {
    return {
      activeName: "video",
      maxHeight: document.documentElement.clientHeight - 290,
      videoList: [],
      videoPage: 1,
      videoTotalRows: 0,
      fileList: [],
      filePage: 1,
      fileTotalRows: 0,
    };
  },
  mounted() {
    this.getVideoList();
  },
  methods: {
    handleClick(tab) {
      this.activeName = tab.name;
      this.handleChangePage(1);
    },
    getVideoList() {
      CourseApi.videoList({
        pageNo: this.videoPage,
        pageSize: 20,
      }).then((res) => {
        if (res.success) {
          this.videoList = res.data;
          this.videoTotalRows = res.totalRows;
        }
      });
    },
    getFileList() {
      CourseApi.fileList({
        pageNo: this.filePage,
        pageSize: 20,
      }).then((res) => {
        if (res.success) {
          this.fileList = res.data;
          this.fileTotalRows = res.totalRows;
        }
      });
    },
    handleChangePage(page) {
      if (this.activeName === "video") {
        this.videoPage = page;
        this.getVideoList();
      } else {
        this.filePage = page;
        this.getFileList();
      }
    },
    add() {
      this.$router.push({
        path: "/file/add",
        query: { type: this.activeName },
      });
    },
    edit(id, filename) {
      this.$router.push({
        path: "/file/edit",
        query: { type: this.activeName, id, filename },
      });
    },
    formatDuration(val) {
      let hour =  Math.floor(Math.floor(val / 1000) / 3600)
      let minute = Math.floor(Math.floor(val / 1000 - hour * 3600) / 60)
      let second = Math.floor(val / 1000) % 60
      return hour ? (hour + '小时') : '' + minute + '分' + second + '秒'
    },
  },
};
</script>

<style></style>
